export default function FillBlank(props) {
  const fn = props.fn;

  const handleGuess = async (e) => {
    e.preventDefault();

    let numOfCorrect = 0;

    for (let i = 0; i < props.aFill.length; i++) {
      if (props.aFill[i] == document.getElementById("a" + i).value) {
        document.getElementById("a" + i).style.backgroundColor =
          "rgba(156, 255, 138, 0.5)";
        numOfCorrect++;
      } else {
        document.getElementById("a" + i).style.backgroundColor =
          "rgba(255, 146, 138, 0.5)";
      }
    }

    if (numOfCorrect === props.aFill.length) {
      console.log("correct");
      document.getElementById("correct").style.visibility = "visible";

      setTimeout(() => {
        document.getElementById("correct").style.visibility = "hidden";
        fn(true);
      }, 500);
    } else {
      console.log("incorrect");
      document.getElementById("incorrect").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("incorrect").style.visibility = "hidden";
      }, 500);
    }
  };

  return (
    <div>
      <form onSubmit={(e) => handleGuess(e)}>
        {props.qFill.map((element) => {
          if (props.qFill.indexOf(element) == props.qFill.length - 1) {
            return (
              <p key={element} className="inline text-[2vw]">
                {element}
              </p>
            );
          } else {
            return (
              <p className="inline text-[2vw]" key={element}>
                {element}
                <input
                  className="w-[12vw] outline-none m-2 border-b-charcoal border-b-2 bg-alabaster rounded-md p-2 transition-colors"
                  type="number"
                  id={"a" + props.qFill.indexOf(element)}
                ></input>
              </p>
            );
          }
        })}
        <div>
          <button
            type="submit"
            className="p-4 m-4 bg-green-700 hover:bg-slateGray active:bg-timberWolf text-[2vw] text-alabaster active:charcoal rounded-md"
          >
            Submit Answer
          </button>
        </div>
      </form>
    </div>
  );
}
