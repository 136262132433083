import { useState } from "react";

export default function Checkbox(props) {
  const fn = props.fn;

  const [guesses, setGuesses] = useState([]);

  const handleGuess = async () => {
    let correct = true;

    if (guesses.length !== props.aCheckbox.length) {
      correct = false;
    } else {
      for (let element of guesses) {
        if (!props.aCheckbox.includes(element)) {
          correct = false;
          break;
        }
      }
    }

    if (correct) {
      console.log("correct");
      document.getElementById("correct").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("correct").style.visibility = "hidden";
        fn(true);
      }, 500);
    } else {
      console.log("incorrect");
      document.getElementById("incorrect").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("incorrect").style.visibility = "hidden";
      }, 500);
    }
  };

  return (
    <div>
      <div className="flex justify-center flex-wrap gap-8">
        {props.qCheckbox.map((element) => {
          return (
            <button
              className="text-[1vw] font-semibold bg-charcoal text-alabaster p-[2vw] h-[10vw] w-[20%] max-md:w-[80%] max-md:text-[5vw] max-md:h-[20vw] hover:bg-slateGray rounded-lg active:bg-cadetGray"
              key={element}
              id={element}
              onClick={() => {
                if (guesses.includes(props.qCheckbox.indexOf(element))) {
                  setGuesses(
                    guesses.filter(
                      (guess) => guess !== props.qCheckbox.indexOf(element)
                    )
                  );
                  document.getElementById(element).style.backgroundColor = null;
                } else {
                  setGuesses([...guesses, props.qCheckbox.indexOf(element)]);
                  document.getElementById(element).style.backgroundColor =
                    "rgb(0, 29, 43)";
                }
              }}
            >
              {element}
            </button>
          );
        })}
      </div>
      <button
        onClick={() => {
          handleGuess();
        }}
        className="p-4 m-4 bg-green-700 hover:bg-slateGray active:bg-timberWolf text-[2vw] text-alabaster active:charcoal rounded-md"
      >
        Submit Answer
      </button>
    </div>
  );
}
