import { useState } from "react";

export default function MultipleChoice(props) {
  const fn = props.fn;

  const handleGuess = async (guess) => {
    if (guess === props.a) {
      console.log("correct");
      document.getElementById("correct").style.visibility = "visible";

      setTimeout(() => {
        document.getElementById("correct").style.visibility = "hidden";
        fn(true);
      }, 500);
    } else {
      console.log("incorrect");
      document.getElementById("incorrect").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("incorrect").style.visibility = "hidden";
        fn(false);
      }, 500);
    }
  };

  return (
    <div>
      <div className="flex justify-center flex-wrap gap-8">
        <button
          className="text-[3vw] font-semibold bg-charcoal text-alabaster p-[2vw] h-[15vw] w-[40%] max-md:w-[80%] max-md:text-[5vw] max-md:h-[20vw] hover:bg-slateGray rounded-lg active:bg-cadetGray"
          onClick={() => {
            handleGuess(1);
          }}
        >
          {props.option_1}
        </button>
        <button
          className="text-[3vw] font-semibold bg-charcoal text-alabaster p-[2vw] h-[15vw] w-[40%] max-md:w-[80%] max-md:text-[5vw] max-md:h-[20vw] hover:bg-slateGray rounded-lg active:bg-cadetGray"
          onClick={() => {
            handleGuess(2);
          }}
        >
          {props.option_2}
        </button>
        <button
          className="text-[3vw] font-semibold bg-charcoal text-alabaster p-[2vw] h-[15vw] w-[40%] max-md:w-[80%] max-md:text-[5vw] max-md:h-[20vw] hover:bg-slateGray rounded-lg active:bg-cadetGray"
          onClick={() => {
            handleGuess(3);
          }}
        >
          {props.option_3}
        </button>
        <button
          className="text-[3vw] font-semibold bg-charcoal text-alabaster p-[2vw] h-[15vw] w-[40%] max-md:w-[80%] max-md:text-[5vw] max-md:h-[20vw] hover:bg-slateGray rounded-lg active:bg-cadetGray"
          onClick={() => {
            handleGuess(4);
          }}
        >
          {props.option_4}
        </button>
      </div>
    </div>
  );
}
