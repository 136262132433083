export const SET_1 = [
  {
    type: "word",
    q: "What is the term to reduce the value of intangible assets over a period of time?",
    a: "amortization",
  },
  {
    type: "multiple",
    q: "Which method is typically used for intangible assets",
    option_1: "Declining Balance",
    option_2: "Percentage Based",
    option_3: "Straight-Line",
    option_4: "Sum-of-the-Years' Digits",
    a: 3,
  },
  {
    type: "fill",
    q: "On January 1, 2023, JGT Airlines purchased a used airplane for $34,250,000. JGT Expects the plane to last for 5 years and have $5,790,000 salvage value. Compute the depreciation per year with the Straight-Line method:",
    qFill: ["Straight-line Method: (", "-", ") / (", ") = $", "per year"],
    aFill: [34250000, 5790000, 5, 5692000],
  },
  {
    type: "t/f",
    q: "Amortization is an estimate adjustment",
    a: true,
  },
  {
    type: "checkbox",
    q: "Which GAAP rules are linked with amortization/depreciation? Select all that apply",
    qCheckbox: [
      "Principle of Conservatism",
      "Matching Principle",
      "Revenue Recognition Principle",
      "Objectivity Principle",
      "Materiality Principle",
    ],
    aCheckbox: [0, 1, 3],
  },
  {
    type: "table",
    q: "JGT T-Shirt Company decides they need to purchase a Cricut to apply their design to the shirt. New it costs $1,000, and it declines at 40% in value per year. Calculate the amortization for the next four years by completing the table.",
    qTable: [
      ["2019", null, null, null, null, "1000"],
      ["2020", "1000", "40%", "400", "400", "600"],
      ["2021", "input", "40%", "input", "input", "input"],
      ["2022", "input", "40%", "input", "input", "input"],
      ["2023", "input", "40%", "input", "input", "input"],
    ],
    aTable: [600, 240, 640, 360, 360, 144, 784, 216, 216, 86.4, 870.4, 129.6],
  },
];

export const SET_2 = [
  {
    type: "checkbox",
    q: "What are some methods to amortize assets? Select all that apply",
    qCheckbox: [
      "Simple Deduction",
      "Matching Principle",
      "Wobbly-Line",
      "Straight-Line",
      "Declining Balance",
    ],
    aCheckbox: [3, 4],
  },
  {
    type: "fill",
    q: "On January 1, 2018, JGT Airlines purchased a used airplane for $86,460,000. JGT Expects the plane to last for 9 years and have $9,870,000 salvage value. Compute the depreciation per year with the Straight-Line method:",
    qFill: ["Straight-line Method: (", "-", ") / (", ") = $", "per year"],
    aFill: [86460000, 9870000, 9, 8510000],
  },
  {
    type: "multiple",
    q: "What is an asset that has no physical substance?",
    option_1: "Intangible",
    option_2: "Tangible",
    option_3: "Untangible",
    option_4: "Tangleable",
    a: 1,
  },
  {
    type: "word",
    q: "What is the estimate of the asset's value at the end of its useful life?",
    a: "salvage value",
  },
  {
    type: "t/f",
    q: "Amortization is an estimate adjustment",
    a: true,
  },
  {
    type: "table",
    q: "JGT Pen Company decides they need to purchase a 3D printer to create pen caps. New it costs $3,400, and it declines at 30% in value per year. Calculate the amortization for the next four years by completing the table.",
    qTable: [
      ["2019", null, null, null, null, "3400"],
      ["2020", "3400", "30%", "1020", "1020", "2380"],
      ["2021", "input", "30%", "input", "input", "input"],
      ["2022", "input", "30%", "input", "input", "input"],
      ["2023", "input", "30%", "input", "input", "input"],
    ],
    aTable: [
      2380, 714, 1734, 1666, 1666, 499.8, 2233.8, 1166.2, 1166.2, 349.86,
      2583.66, 816.34,
    ],
  },
];

export const SET_3 = [
  {
    type: "checkbox",
    q: "What are some types of accounts affected by amortization? Select all that apply",
    qCheckbox: [
      "Assets",
      "Drawings",
      "Contra-Assets",
      "Petty Cash",
      "Amortization Expenses",
      "Liabilities",
      "Accounts Receivable",
    ],
    aCheckbox: [0, 2, 4],
  },
  {
    type: "multiple",
    q: "How do you depreciate land?",
    option_1: "Increasing expenses",
    option_2: "Decreasing revenue",
    option_3: "Decreasing acc. depreciation",
    option_4: "You Don't",
    a: 4,
  },
  {
    type: "multiple",
    q: "Which GAAP rule does amortization NOT affect?",
    option_1: "Principle of Conservatism",
    option_2: "Matching Principle",
    option_3: "Revenue Recognition Principle",
    option_4: "Objectivity Principle",
    a: 3,
  },
  {
    type: "word",
    q: "What is the estimate of expected life based on a need for repair or service life of an asset?",
    a: "useful life",
  },
  {
    type: "table",
    q: "JGT Technologies decides they need to purchase a super computer to take over the world. New it costs $64,700, and it declines at 15% in value per year. Calculate the amortization for the next four years by completing the table.",
    qTable: [
      ["2019", null, null, null, null, "64700"],
      ["2020", "64700", "15%", "9705", "9705", "54995"],
      ["2021", "input", "15%", "input", "input", "input"],
      ["2022", "input", "15%", "input", "input", "input"],
      ["2023", "input", "15%", "input", "input", "input"],
    ],
    aTable: [
      54995, 8249.25, 17954.25, 46745.75, 46745.75, 7011.86, 24966.11, 39733.89,
      39733.89, 5960.08, 30926.19, 33773.81,
    ],
  },
  {
    type: "fill",
    q: "On January 1, 2012, JGT Candy Store purchased a cotton candy machine patent for $4,600. JGT Expects the patent to last for 15 years and have $1,405 salvage value. Compute the depreciation per year with the Straight-Line method:",
    qFill: ["Straight-line Method: (", "-", ") / (", ") = $", "per year"],
    aFill: [4600, 1405, 15, 213],
  },
];

export const SET_4 = [
  {
    type: "multiple",
    q: "Which type of account is accumulated amortization",
    option_1: "Contra-Liability",
    option_2: "Asset",
    option_3: "Liability",
    option_4: "Contra-Asset",
    a: 4,
  },
  {
    type: "t/f",
    q: "Land is depreciable",
    a: false,
  },
  {
    type: "multiple",
    q: "Which method is typically used for intangible assets",
    option_1: "Declining Balance",
    option_2: "Percentage Based",
    option_3: "Straight-Line",
    option_4: "Sum-of-the-Years' Digits",
    a: 3,
  },
  {
    type: "checkbox",
    q: "What assets are amortizable/depreciable? Select all that apply",
    qCheckbox: [
      "Buildings",
      "Land",
      "Equipment",
      "Petty Cash",
      "Supplies",
      "Copyrights",
      "Patents",
    ],
    aCheckbox: [0, 2, 4, 5, 6],
  },
  {
    type: "table",
    q: "JGT Accounting decides they need to purchase a quantum computer to manage their clients' accounts. New it costs $103,900, and it declines at 5% in value per year. Calculate the amortization for the next four years by completing the table.",
    qTable: [
      ["2019", null, null, null, null, "103900"],
      ["2020", "103900", "5%", "5195", "5195", "98705"],
      ["2021", "input", "5%", "input", "input", "input"],
      ["2022", "input", "5%", "input", "input", "input"],
      ["2023", "input", "5%", "input", "input", "input"],
    ],
    aTable: [
      98705, 4935.25, 10130.25, 93769.75, 93769.75, 4688.49, 14818.74, 89081.26,
      89081.26, 4454.06, 19272.8, 84627.2,
    ],
  },
  {
    type: "fill",
    q: "On January 1, 2012, JGT Gaming Store purchased a customer tracking software license for $1,224. JGT Expects the machine to last for 30 years and have $24 salvage value. Compute the depreciation per year with the Straight-Line method:",
    qFill: ["Straight-line Method: (", "-", ") / (", ") = $", "per year"],
    aFill: [1224, 24, 30, 40],
  },
];
