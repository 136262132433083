import { useState } from "react";

export default function TrueFalse(props) {
  const fn = props.fn;

  const handleGuess = async (guess) => {
    if (guess === props.a) {
      console.log("correct");
      document.getElementById("correct").style.visibility = "visible";

      setTimeout(() => {
        document.getElementById("correct").style.visibility = "hidden";
        fn(true);
      }, 500);
    } else {
      console.log("incorrect");
      document.getElementById("incorrect").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("incorrect").style.visibility = "hidden";
        fn(false);
      }, 500);
    }
  };

  return (
    <div>
      <div className="flex justify-center flex-wrap gap-8">
        <button
          className="text-[6vw] font-semibold bg-charcoal text-alabaster p-[2vw] h-[25vw] w-[40%] max-md:w-[80%] max-md:text-[5vw] max-md:h-[20vw] hover:bg-slateGray rounded-lg active:bg-cadetGray"
          onClick={() => {
            handleGuess(true);
          }}
        >
          True
        </button>
        <button
          className="text-[6vw] font-semibold bg-charcoal text-alabaster p-[2vw] h-[25vw] w-[40%] max-md:w-[80%] max-md:text-[5vw] max-md:h-[20vw] hover:bg-slateGray rounded-lg active:bg-cadetGray"
          onClick={() => {
            handleGuess(false);
          }}
        >
          False
        </button>
      </div>
    </div>
  );
}
