import { useState } from "react";

export default function TypeAnswer(props) {
  const [guess, setGuess] = useState("");

  const fn = props.fn;

  const handleGuess = (e) => {
    e.preventDefault();

    if (guess.toLowerCase() === props.a.toLowerCase()) {
      console.log("correct");

      document.getElementById("correct").style.visibility = "visible";

      setTimeout(() => {
        document.getElementById("correct").style.visibility = "hidden";
        fn(true);
      }, 500);
    } else {
      console.log("incorrect");
      document.getElementById("incorrect").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("incorrect").style.visibility = "hidden";
        // fn(false);
      }, 500);
    }
  };
  return (
    <div>
      <form onSubmit={(e) => handleGuess(e)}>
        <input
          className="bg-transparent outline-none border-transparent border-b-2 border-b-charcoal text-charcoal p-4 text-[4vw]"
          onChange={(e) => setGuess(e.target.value)}
        ></input>
      </form>
    </div>
  );
}
