import { useEffect, useState } from "react";
import "./App.css";
import GameContainer from "./components/GameContainer";

function App() {
  const [type, setType] = useState("");
  const [locked, setLocked] = useState(true);
  const [passcode, setPasscode] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("type")) {
      const num = Math.ceil(Math.random() * 4);
      setType(
        num === 1
          ? "A"
          : num === 2
          ? "B"
          : num === 3
          ? "C"
          : num === 4
          ? "D"
          : null
      );

      localStorage.setItem(
        "type",
        num === 1
          ? "A"
          : num === 2
          ? "B"
          : num === 3
          ? "C"
          : num === 4
          ? "D"
          : null
      );
    } else {
      setType(localStorage.getItem("type"));
    }

    if (localStorage.getItem("locked") === "false") {
      setLocked(false);
    } else {
      setLocked(true);
    }
  }, []);

  return (
    <div>
      <div className="App">
        {locked ? (
          <div>
            <div
              className="bg-red-500/90 text-alabaster p-2 w-[80%] text-[10vw]"
              id="incorrect"
              style={{ visibility: "hidden" }}
            >
              INCORRECT PASSWORD
            </div>
            <div className="font-bold text-[5vw] py-24">LOCKED</div>
            <input
              type="password"
              onChange={(e) => setPasscode(e.target.value)}
              className="text-[3vw] outline-none border-b-2 border-b-cadetGray hover:border-b-charcoal hover:bg-cadetGray focus:border-b-charcoal p-2 focus:bg-slateGray rounded-md transition-all"
            ></input>
            <div>
              <button
                className="my-12 py-2 bg-charcoal text-alabaster hover:bg-slateGray active:bg-cadetGray active:text- rounded-md w-[12vw] text-[2vw]"
                onClick={() => {
                  if (passcode === "Manchester United") {
                    setLocked(false);
                    localStorage.clear();
                    localStorage.setItem("locked", "false");
                  } else {
                    document.getElementById("incorrect").style.visibility =
                      "visible";
                    setTimeout(() => {
                      document.getElementById("incorrect").style.visibility =
                        "hidden";
                    }, 500);
                  }
                }}
              >
                Enter
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="bg-red-500/90 text-alabaster p-2 w-[80%] text-[10vw]"
              id="incorrect"
              style={{ visibility: "hidden" }}
            >
              INCORRECT
            </div>

            <div
              className="bg-green-500/90 text-alabaster p-2 w-[80%] text-[10vw]"
              id="correct"
              style={{ visibility: "hidden" }}
            >
              CORRECT
            </div>
            {type && <GameContainer type={type}></GameContainer>}
          </div>
        )}
      </div>
      <div className="fixed bottom-0 left-0 text-[2vw]">
        Made with ❤️ by Jaden Z
      </div>
    </div>
  );
}

export default App;
