export default function TableAnswer(props) {
  const fn = props.fn;

  const handleGuess = async (e) => {
    e.preventDefault();

    let numOfCorrect = 0;

    for (let i = 0; i < props.aTable.length; i++) {
      if (props.aTable[i] == document.getElementById("ta" + i).value) {
        document.getElementById("ta" + i).style.backgroundColor =
          "rgba(156, 255, 138, 0.5)";
        numOfCorrect++;
      } else {
        document.getElementById("ta" + i).style.backgroundColor =
          "rgba(255, 146, 138, 0.5)";
      }
    }

    if (numOfCorrect === props.aTable.length) {
      console.log("correct");
      document.getElementById("correct").style.visibility = "visible";

      setTimeout(() => {
        document.getElementById("correct").style.visibility = "hidden";
        fn(true);
      }, 500);
    } else {
      console.log("incorrect");
      document.getElementById("incorrect").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("incorrect").style.visibility = "hidden";
      }, 500);
    }
  };

  return (
    <div className="flex justify-center">
      <form onSubmit={(e) => handleGuess(e)}>
        <table>
          <tr>
            <th>Year</th>
            <th>Net Book Value, Beginning of Year</th>
            <th>x</th>
            <th>Amortization Rate</th>
            <th>=</th>
            <th>Amortization Expense</th>
            <th>Accumulated Amortization</th>
            <th>Net Book Value</th>
          </tr>
          {props.qTable.map((element) => {
            return (
              <tr key={element}>
                <td>{element[0]}</td>
                {element[1] === "input" ? (
                  <td>
                    <input
                      className="bg-transparent p-[1vw] outline-none border-b-2 border-b-charcoal focus:bg-timberWolf rounded-md transition-colors text-[1.3vw] w-[16vw]"
                      type="number"
                      step=".01"
                      id={"ta" + (props.qTable.indexOf(element) - 2) * 4}
                    ></input>
                  </td>
                ) : (
                  <td>{element[1]}</td>
                )}
                <td></td>
                <td>{element[2]}</td>
                <td></td>
                {element[3] === "input" ? (
                  <td>
                    <input
                      className="bg-transparent p-[1vw] outline-none border-b-2 border-b-charcoal focus:bg-timberWolf rounded-md transition-colors text-[1.3vw] w-[16vw]"
                      type="number"
                      step=".01"
                      id={"ta" + ((props.qTable.indexOf(element) - 2) * 4 + 1)}
                    ></input>
                  </td>
                ) : (
                  <td>{element[3]}</td>
                )}
                {element[4] === "input" ? (
                  <td>
                    <input
                      className="bg-transparent p-[1vw] outline-none border-b-2 border-b-charcoal focus:bg-timberWolf rounded-md transition-colors text-[1.3vw] w-[16vw]"
                      type="number"
                      step=".01"
                      id={"ta" + ((props.qTable.indexOf(element) - 2) * 4 + 2)}
                    ></input>
                  </td>
                ) : (
                  <td>{element[4]}</td>
                )}
                {element[5] === "input" ? (
                  <td>
                    <input
                      className="bg-transparent p-[1vw] outline-none border-b-2 border-b-charcoal focus:bg-timberWolf rounded-md transition-colors text-[1.3vw] w-[16vw]"
                      type="number"
                      step=".01"
                      id={"ta" + ((props.qTable.indexOf(element) - 2) * 4 + 3)}
                    ></input>
                  </td>
                ) : (
                  <td>{element[5]}</td>
                )}
              </tr>
            );
          })}
        </table>
        <button
          type="submit"
          className="p-4 m-4 bg-green-700 hover:bg-slateGray active:bg-timberWolf text-[2vw] text-alabaster active:charcoal rounded-md"
        >
          Submit Answer
        </button>
      </form>
    </div>
  );
}
