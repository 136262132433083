import { SET_1, SET_2, SET_3, SET_4 } from "../Questions";
import { useEffect, useState } from "react";
import MultipleChoice from "./MultipleChoice";
import TypeAnswer from "./TypeAnswer";
import FillBlank from "./FillBlank";
import TrueFalse from "./TrueFalse";
import Checkbox from "./Checkbox";
import TableAnswer from "./TableAnswer";
import confetti from "https://cdn.skypack.dev/canvas-confetti";

export default function GameContainer(props) {
  const [q, setQ] = useState("");
  const [a, setA] = useState("");
  const [option_1, setOption_1] = useState("");
  const [option_2, setOption_2] = useState("");
  const [option_3, setOption_3] = useState("");
  const [option_4, setOption_4] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [qType, setQType] = useState("");
  const [incomplete, setIncomplete] = useState([]);
  const [won, setWon] = useState(false);
  const [percentage, setPercentage] = useState(0.0);
  const [qFill, setQFill] = useState([]);
  const [aFill, setAFill] = useState([]);
  const [qCheckbox, setQCheckbox] = useState([]);
  const [aCheckbox, setACheckbox] = useState([]);
  const [qTable, setQTable] = useState([]);
  const [aTable, setATable] = useState([]);
  const [isRealWin, setIsRealWin] = useState(false);

  const type = props.type;

  function makeConfetti() {
    confetti();
  }

  const QUESTION_SET =
    type === "A"
      ? SET_1
      : type === "B"
      ? SET_2
      : type === "C"
      ? SET_3
      : type === "D"
      ? SET_4
      : null;

  const triggerWinScreen = async () => {
    setIsRealWin(true);
    for (let i = 0; i < 5; i++) {
      setTimeout(() => {
        makeConfetti();
      }, 500 * i);
    }
  };

  const nextQuestion = (correct) => {
    let localIncomplete = localStorage.getItem("incomplete").split(",");

    if (correct) {
      if (localIncomplete.length === 1) {
        localStorage.removeItem("incomplete");
        setWon(true);
        localStorage.setItem("won", true);
        setPercentage(1);
      } else {
        localIncomplete.splice(localIncomplete.indexOf(currentIndex), 1);
        localStorage.setItem("incomplete", localIncomplete);
      }
    }
    if (QUESTION_SET && localIncomplete.length !== 0) {
      let index =
        localIncomplete[
          Math.round(Math.random() * (localIncomplete.length - 1))
        ];
      if (localIncomplete.length > 1) {
        do {
          index =
            localIncomplete[
              Math.round(Math.random() * (localIncomplete.length - 1))
            ];
        } while (index === currentIndex);
      }
      setCurrentIndex(index);
      setQ(QUESTION_SET[index]?.q);
      setA(QUESTION_SET[index]?.a);
      setQType(QUESTION_SET[index]?.type);
      if (QUESTION_SET[index]?.type === "multiple") {
        setOption_1(QUESTION_SET[index]?.option_1);
        setOption_2(QUESTION_SET[index]?.option_2);
        setOption_3(QUESTION_SET[index]?.option_3);
        setOption_4(QUESTION_SET[index]?.option_4);
      } else if (QUESTION_SET[index]?.type === "fill") {
        setQFill(QUESTION_SET[index]?.qFill);
        setAFill(QUESTION_SET[index]?.aFill);
      } else if (QUESTION_SET[index]?.type === "checkbox") {
        setQCheckbox(QUESTION_SET[index]?.qCheckbox);
        setACheckbox(QUESTION_SET[index]?.aCheckbox);
      } else if (QUESTION_SET[index]?.type === "table") {
        setQTable(QUESTION_SET[index]?.qTable);
        setATable(QUESTION_SET[index]?.aTable);
      }
    }
    if (!localStorage.getItem("won")) {
      setPercentage(
        (QUESTION_SET.length - localIncomplete.length) / QUESTION_SET.length
      );
    }
  };

  const reset = () => {
    if (localStorage.getItem("won")) {
      setWon(true);
    } else if (QUESTION_SET) {
      if (localStorage.getItem("incomplete")) {
        setIncomplete(localStorage.getItem("incomplete").split(","));
      } else {
        let localIncomplete = [];
        for (let i = 0; i < QUESTION_SET.length; i++) {
          localIncomplete.push(i);
          setIncomplete([...incomplete, i]);
        }
        localStorage.setItem("incomplete", localIncomplete);
      }
      nextQuestion(false);
    }
  };

  useEffect(() => {
    reset();
  }, []);
  return (
    <div>
      {isRealWin ? (
        <div>
          <div className="text-[8vw] py-64 text-green-700 font-black">
            CONGRATULATIONS!
          </div>
          <div>Show this screen to the presenters for your prize :)</div>
        </div>
      ) : (
        <div>
          <div className="w-[100%] bg-green-500/30 py-3 flex justify-start items-center fixed">
            <div
              className="progressBar"
              style={{ width: percentage * 100 + "%" }}
            ></div>
          </div>
          {won ? (
            <div className="py-64">
              <div>
                Good job at completing the questions! Here is a piece of your
                code:{" "}
              </div>
              {props.type === "A" && (
                <div className="text-[8vw] font-bold">
                  3 2 5 _ _ _ _ _ _ _ _ _
                </div>
              )}
              {props.type === "B" && (
                <div className="text-[8vw] font-bold">
                  _ _ _ 1 8 6 _ _ _ _ _ _
                </div>
              )}
              {props.type === "C" && (
                <div className="text-[8vw] font-bold">
                  _ _ _ _ _ _ 9 8 3 _ _ _
                </div>
              )}
              {props.type === "D" && (
                <div className="text-[8vw] font-bold">
                  _ _ _ _ _ _ _ _ _ 0 8 2
                </div>
              )}
              <div className="text-[2vw]">You are student {props.type}</div>
              <div className="text-[2vw]">
                The code can only be completed once students A, B, C, D are
                together!
              </div>
              <input
                placeholder="Input Code"
                className="p-4 outline-none border-b-2 border-b-charcoal text-[3vw] tracking-[1vw]"
                onChange={(e) => {
                  if (
                    e.target.value?.length === 12 &&
                    e.target.value === "325186983082"
                  ) {
                    triggerWinScreen();
                  }
                }}
              ></input>
              <br></br>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    localStorage.removeItem("won");
                    setWon(false);
                    reset();
                  }}
                  className="fixed bottom-0 p-4 bg-red-400 text-alabaster rounded-md hover:bg-red-500 active:bg-red-600"
                >
                  Restart
                </button>
              </div>
            </div>
          ) : (
            <>
              <h1 className=" text-[4vw] pt-24 p-20">{q}</h1>
              {qType === "multiple" && (
                <MultipleChoice
                  fn={nextQuestion}
                  option_1={option_1}
                  option_2={option_2}
                  option_3={option_3}
                  option_4={option_4}
                  a={a}
                ></MultipleChoice>
              )}
              {qType === "t/f" && (
                <TrueFalse fn={nextQuestion} a={a}></TrueFalse>
              )}
              {qType === "word" && (
                <TypeAnswer fn={nextQuestion} a={a}></TypeAnswer>
              )}
              {qType === "fill" && (
                <FillBlank
                  fn={nextQuestion}
                  qFill={qFill}
                  aFill={aFill}
                ></FillBlank>
              )}
              {qType === "checkbox" && (
                <Checkbox
                  fn={nextQuestion}
                  qCheckbox={qCheckbox}
                  aCheckbox={aCheckbox}
                ></Checkbox>
              )}
              {qType === "table" && (
                <TableAnswer
                  fn={nextQuestion}
                  qTable={qTable}
                  aTable={aTable}
                ></TableAnswer>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
